.job-teaser {
    position: relative;
    font-size: calc(15rem / 16);
    line-height: calc(20/15);
    padding: calc(20rem / 16) calc(40rem / 16) calc(20rem / 16) calc(15rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(42rem / 16) calc(60rem / 16) calc(42rem / 16) calc(33rem / 16);
    }
}
.job-teaser__title {
    font-size: calc(20rem / 16);
    line-height: calc(35/33);
    letter-spacing: calc(2rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(33rem / 16);
        letter-spacing: calc(5rem / 16);
    }
}
.job-teaser__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: calc(15rem / 16);
    font-size: calc(10rem / 16);
    transition: transform 200ms ease-in-out;

    @media screen and (min-width: 768px) {
        right: calc(30rem / 16);
    }
}
.job-teaser:hover .job-teaser__arrow {
    transform: translateX(calc(5rem / 16)) translateY(-50%);
}

/* large */
.job-teaser--lg .job-teaser__title {
    margin-top: calc(25rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(80rem / 16);
    }
}
.job-teaser--lg .job-teaser__arrow {
    display: none;
}