.metabar {
    margin: calc(30rem / 16) 0;
    letter-spacing: calc(2rem / 16);

    @media screen and (min-width: 768px) {
        margin: calc(55rem / 16) 0;
    }

    @media screen and (min-width: 1200px) {
        letter-spacing: calc(5rem / 16);
    }
}
.metabar__item {
    position: relative;
}
.metabar__item:not(:first-child) {
    margin-top: calc(5rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: 0;
        margin-left: calc(10rem / 16);
        padding-left: calc(18rem / 16);
    }
}
.metabar__item:not(:first-child):before {
    @media screen and (min-width: 768px) {
        content: '';
        position: absolute;
        left: 0;
        top: calc(8rem / 16);
        bottom: calc(6rem / 16);
        width: calc(2rem / 16);
        background: var(--color-text-default);
    }
}