.press-teaser {
    position: relative;
    overflow: hidden;
}
.press-teaser:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--color-primary);
    opacity: .2;
    transition: opacity 200ms ease-in-out;
}
.press-teaser--gradient-overlay:after {
    background: linear-gradient(180deg, rgba(0,54,108,0) 0%, var(--color-primary) 100%);
    opacity: .75;
}
.press-teaser--no-overlay:after {
    content: none;
}
.press-teaser.clickable:hover:after {
    opacity: .5;
}
.press-teaser__img {
    transition: transform 200ms ease-in-out;
}
.press-teaser.clickable:hover .press-teaser__img {
    transform: scale(1.02);
}
.press-teaser__content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    padding: calc(15rem / 16);

    max-height: 65%;

    @media screen and (min-width: 1400px) {
        padding: calc(15rem / 16) calc(15rem / 16) calc(33rem / 16) calc(33rem / 16);
    }
}
.press-teaser__content__arrow {
    position: absolute;
    right: calc(16rem / 16);
    bottom: calc(16rem / 16);
    border-style: solid;
    border-width: 0 0 calc(16rem / 16) calc(16rem / 16);
    border-color: transparent transparent var(--color-primary) transparent;
    transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
    opacity: 0;
    transform: translateX(calc(16rem / 16)) translateY(calc(16rem / 16));
}
.press-teaser:hover .press-teaser__content__arrow {
    transform: translateX(0) translateY(0);
    opacity: 1;
}
.press-teaser--no-overlay.clickable:hover .press-teaser__content__title {
    text-decoration: underline;
}
.press-teaser__pretitle {
    position: absolute;
    padding: calc(15rem / 16);
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;
    font-size: calc(15rem / 16);
    letter-spacing: calc(5rem / 16);

    @media screen and (min-width: 1400px) {
        padding: calc(33rem / 16);
    }
}

.press-teaser__content__title {
    letter-spacing: calc(2rem / 16);
    font-size: calc(20rem / 16);
    line-height: 1;

    @media screen and (min-width: 768px) {
        letter-spacing: calc(5rem / 16);
        font-size: calc(25rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(33rem / 16);
    }
}

/* detail content */
.press-teaser__content.press-teaser__content--detail {
    transform: translateY(100%);
    padding: 0;
    transition: transform 200ms ease-in-out;
}
.press-teaser__content--detail .press-teaser__content__title {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    padding: calc(15rem / 16);

    @media screen and (min-width: 1400px) {
        padding: calc(33rem / 16);
    }
}
.press-teaser__content__detail {
    padding: calc(10rem / 16) calc(15rem / 16);
    font-size: calc(15rem / 16);
    line-height: calc(26/15);

    @media screen and (min-width: 1200px) {
        font-size: calc(17rem / 16);
        line-height: calc(33/17);
        padding: calc(25rem / 16) calc(33rem / 16);
    }
}
.press-teaser__content__detail__link {
    font-size: calc(13rem / 16);
    letter-spacing: calc(2rem / 16);

    @media screen and (min-width: 1200px) {
        font-size: calc(15rem / 16);
    }
}
.press-teaser:hover .press-teaser__content--detail {
    transform: translateY(0);
}

/* small img teaser */
.press-teaser--sm .press-teaser__content__title {
    font-size: calc(21rem / 16);
    line-height: calc(29/27);

    @media screen and (min-width: 1400px) {
        font-size: calc(27rem / 16);
    }
}