html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
    body {
        width: calc(982rem/16);
    }
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
    .container--no-padding-xs {
        padding: 0;
        overflow: hidden;
    }
}
.container.container {
    max-width: calc(1520rem/16);
    width: 100%;
}
.container {
    padding-left: calc(15rem / 16);
    padding-right: calc(15rem / 16);
}
.container-md {
    max-width: calc(1240rem / 16);
    margin-left: auto;
    margin-right: auto;
}
.container-narrow {
    max-width: calc(990rem / 16);
    margin-left: auto;
    margin-right: auto;
}
.container-xs {
    max-width: calc(740rem / 16);
    margin-left: auto;
    margin-right: auto;
}

.page-wrapper {
    overflow: hidden;
}
.page-wrapper:before {
    content: '';
    position: fixed;
    pointer-events: none;
    bottom: 0;
    left: 0;
    top: 0;
    width: 100%;
    background-image: url('/static/debug/img/branding-visual.svg');
    background-repeat: no-repeat;
    background-position: calc(-30rem / 16) bottom;
    background-size: contain;

    @media screen and (min-width: 768px){
        width: 75%;
    }
}
main {
    position: relative;
}