.img-teaser {
    position: relative;
    overflow: hidden;
}
.img-teaser:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--color-primary);
    opacity: .5;
    transition: opacity 200ms ease-in-out;
}
.img-teaser:hover:after {
    opacity: .9;
}
.img-teaser__img {
    transition: transform 200ms ease-in-out;
}
.img-teaser:hover .img-teaser__img {
    transform: scale(1.02);
}
.img-teaser__content {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    padding: calc(15rem / 16);
}
.img-teaser__content__title {
    letter-spacing: calc(2rem / 16);
    font-size: calc(20rem / 16);
    line-height: 1;

    @media screen and (min-width: 768px) {
        letter-spacing: calc(5rem / 16);
        font-size: calc(25rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(33rem / 16);
    }
}

.img-teaser__content__text {
    font-size: calc(20rem / 16);
    line-height: calc(16/14);

    @media screen and (min-width: 1400px) {
        padding: 0 calc(40rem / 16);
    }
}