.hero {
    position: relative;
}
.hero:not(.hero--full-height) {
    padding-top: calc(80rem / 16);

    @media screen and (min-width: 768px) {
        padding-top: calc(120rem / 16);
    }
}

/* content */
.hero__content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: calc(15rem / 16);
    z-index: 1;

    @media screen and (min-width: 768px) {
        padding: calc(40rem / 16) calc(33rem / 16);
    }
}
.hero__content.hero__content--center {
    padding: 0;
    bottom: auto;
    top: 50%;
    transform: translateY(-40%);
    opacity: 0;
    animation: 1200ms ease-in-out 500ms 1 fadeIn;
    animation-fill-mode: forwards;

}
.hero__content__title {
    font-size: calc(26rem / 16);
    line-height: 1;

    @media screen and (min-width: 768px) {
        font-size: calc(66rem / 16);
        letter-spacing: calc(6rem / 16);
    }
}
@keyframes fadeIn {
    0% {
        transform: translateY(-40%);
        opacity: 0;
    }
    100% {
        transform: translateY(-50%);
        opacity: 1;
    }
}

/* full height */
.hero--full-height {
    height: 100vh;
    background-size: cover;
    background-position: center;

    @media screen and (min-width: 768px) {
        min-height: calc(800rem / 16);
    }
}
.hero__content__title-lg {
    position: relative;
    text-transform: none;
    padding-bottom: calc(3rem / 16);
    margin-bottom: calc(15rem / 16);
}
.hero__content__title-lg:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: calc(3rem / 16);
    width: calc(94rem / 16);
    border-radius: calc(1.5rem / 16);
    background: currentColor;
}
.hero__content__subtitle {
    font-size: calc(30rem / 16);
    line-height: calc(38/30);
    letter-spacing: calc(2rem / 16);

    @media screen and (min-width: 768px) {
        letter-spacing: calc(4.9rem / 16);
        font-size: calc(54rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(71rem / 16);
    }
}

.hero__content__form {
    margin-top: calc(40rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(80rem / 16);
    }

    @media screen and (min-width: 1200px) {
        margin-top: calc(140rem / 16);
    }
}

.hero.hero--lg{
    padding-top: 0;
    background-size: cover;
    background-position: center;

    @media screen and (min-width: 768px) {
        padding-top: 0;
        height: 100vh;
    }
}
.hero-background-img {
    overflow: hidden;
    background-size: cover;
    background-position: center;
    flex-grow: 1;
    position: relative;
    min-height: 60vh;

    @media screen and (min-width: 768px) and (min-height: 780px) {
        min-height: calc(650rem / 16);
    }
}


.hero--full-height:after,
.hero-background-img:after {
    content: '';
    position: absolute;
    pointer-events: none;
    bottom: 0;
    left: 0;
    top: 0;
    width: 100%;
    background-image: url('/static/debug/img/branding-visual-white.svg');
    background-repeat: no-repeat;
    background-position: calc(-30rem / 16) bottom;
    background-size: contain;
    transform: scale(.95) rotate(4deg) translateX(-4%);
    transform-origin: left bottom;
    opacity: 0;
    animation: 1100ms ease-in-out 1 scale;
    animation-fill-mode: forwards;
    animation-delay: 500ms;

    @media screen and (min-width: 768px){
        width: 75%;
    }
}
@keyframes scale {
    0% {
        transform: scale(.95) rotate(4deg) translateX(-4%);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}


.hero-background-img:after {
    @media screen and (min-width: 768px) {
        bottom: calc(-200rem / 16);
        background-position: calc(-15rem / 16) bottom;
    }
}

.hero__scroll {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: calc(40rem / 16);
    z-index: 1;
    font-size: calc(16rem / 16);

    @media screen and (min-width: 768px) {
        bottom: 18%;
    }
}
.hero__scroll__icon {
    font-size: calc(40rem / 16);
}

.hero--overlay-top:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: calc(70rem / 16);
    background: linear-gradient(180deg,rgba(255, 255, 255, 0.8),hsla(0,0%,100%,0));
    z-index: 1;

    @media screen and (min-width: 768px) {
        height: calc(140rem / 16);
    }
}