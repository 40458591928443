.info-bar {
    padding: calc(20rem / 16) 0 calc(30rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(50rem / 16) 0 calc(60rem/16);
    }
}
.info-bar__title {
    font-size: calc(16rem / 16);
}
.info-bar__value {
    font-size: calc(22rem / 16);
    line-height: calc(25/24);
    margin-top: calc(10rem / 16);
    letter-spacing: calc(2rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(24rem / 16);
        margin-top: calc(32rem / 16);
        letter-spacing: calc(5rem / 16);
    }
}