.quote-container {
    padding-top: calc(50rem / 16);
    font-size: calc(18rem / 16);
    letter-spacing: calc(5rem / 16);

    @media screen and (min-width: 768px) {
        padding-top: calc(80rem / 16);
    }
}
.quote {
    position: relative;
    font-size: calc(24rem / 16);
    line-height: calc(34/24);
    letter-spacing: normal;

    @media screen and (min-width: 768px) {
        font-size: calc(38rem / 16);
        line-height: calc(56/38);
    }
}
.quote:before {
    content: '“';
    position: absolute;
    color: var(--color-dark-grey);
    top: calc(10rem / 16);
    line-height: 0;
    z-index: -1;
    font-size: calc(160rem / 16);
    left: 50%;
    transform: translateX(-50%);

    @media screen and (min-width: 768px) {
        top: 0;
        font-size: calc(220rem / 16);
    }
}