.form-group {
    position: relative;
}
.floating-label {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 200ms ease-in-out;
    transform-origin: left top;
    pointer-events: none;
    z-index: 1;
}

textarea.form-control{
    position: relative;
    top: 0;
    transform: translate(0);
}

textarea ~ .floating-label{
    pointer-events: none;
    position: absolute;
    transform: translate3d(0, 0, 0) scale(1);
    transform-origin: left top;
    transition: 100ms;
    top: 0;
}

textarea.form-control.has-value ~ .floating-label,
textarea.form-control:focus ~ .floating-label{
    transform: translateY(-0%) scale(.65);
}
.form-control:focus ~ .floating-label,
select ~ .floating-label,
.form-control.has-value ~ .floating-label,
.form-group.has-value .floating-label {
    transform: translateY(-125%) scale(.65);
}

.form-control:-webkit-autofill ~ .form-control-label,
.form-control:-webkit-autofill:focus ~ .form-control-label {
    transform: translateY(-125%) scale(.65);
}