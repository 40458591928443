.video-js .vjs-big-play-button {
    border-radius: 50%;
    width: calc(60rem / 16);
    height: calc(60rem / 16);
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    border: 0;
    background-color: rgba(0, 54, 108, 0.8);
    color: #fff;
    transition: background 200ms ease-in-out;

    @media screen and (min-width: 768px) {
        width: calc(120rem / 16);
        height: calc(120rem / 16);
    }
}
.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
    content: var(--icon-play);
    font-family: iconfont;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-40%);
    font-size: calc(22rem / 16);
    height: auto;
    width: auto;
    @media screen and (min-width: 768px) {
        font-size: calc(39rem / 16);
    }
}
.video-js .vjs-big-play-button:focus, .video-js:hover .vjs-big-play-button {
    transition: background 200ms ease-in-out;
    background-color: rgba(0, 54, 108, 1);
}
.video-js .vjs-control-bar {
    background-color: rgba(0, 0, 0, 0.5);
}
.video-js .vjs-slider {
    background-color: rgba(255,255,255,.5);
}