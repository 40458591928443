.teaser-slider {
    @media screen and (max-width: 767px) {
        margin: 0 calc(15rem / 16);
    }
    
    @media screen and (min-width: 768px) and (max-width: 1560px){
       padding: 0 calc(15rem/16);
    }
}

.teaser-slider .slick-arrow.slick-prev{
    @media screen and (min-width: 768px) and (max-width: 1560px){
        left: calc(15rem/16);
    }
}

.teaser-slider .slick-arrow.slick-next{
    @media screen and (min-width: 768px) and (max-width: 1560px){
        right: calc(15rem/16);
    }
}