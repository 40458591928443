.btn {
    text-transform: uppercase;
    letter-spacing: calc(2rem / 16);
    font-family: var(--font-default);
    font-weight: 400;
    font-size: calc(15rem / 16);
    padding: calc(18rem / 16) calc(25rem / 16);
}
.btn-no-styling,
.btn.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}
.btn-secondary:hover {
    background: var(--color-primary);
    color: #fff;
}
.btn-white:hover {
    background: var(--color-primary);
    color: #fff;
}
.btn-breakable { white-space: normal;}

/* button sizes */
.btn-sm {
    padding: calc(8rem / 16) calc(15rem / 16);
    font-size: calc(14rem / 16);
}
.btn-lg {
    padding: calc(20rem / 16) calc(15rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(28rem / 16) calc(15rem / 16);
    }
}
.btn-xl {
    padding: calc(20rem / 16) calc(30rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(28rem / 16) calc(60rem / 16);
    }
}

.btn__icon {
    font-size: calc(8rem / 16);
}