.form-control {
    background: #fff;
    border: 0;
    border-bottom: 1px solid var(--color-primary);
    height: calc(42rem / 16);
    padding: calc(8rem / 16) 0 calc(8rem / 16);
    font-size: calc(15rem / 16);
    line-height: calc(20/15);
    letter-spacing: calc(2rem / 16);
}
.form-control:focus {
    background-color: #fff;
    border-color: var(--color-primary);
}
.form-control--transparent,
.form-control--transparent:focus {
    background: transparent;
}
.form-control--grey,
.form-control--grey:focus {
    background: var(--color-light-grey);
}

.form-control::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: var(--color-dark-grey);
}
.form-control::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: var(--color-dark-grey);
    opacity:  1;
}
.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--color-dark-grey);
}

/* full bordered inputs */
.form-control--bordered {
    border: 1px solid var(--color-primary);
    padding: calc(10rem / 16);
    background: #fff;
}

/* control size */
.form-control-lg {
    font-size: calc(17rem / 16);
    line-height: calc(23/17);
    padding: calc(10rem / 16) calc(15rem / 16);
    border-width: calc(2rem / 16);
    height: calc(60rem / 16);
    font-family: var(--font-default-bold);
    font-weight: 400;

    @media screen and (min-width: 768px) {
        height: calc(84rem / 16);
        padding: calc(15rem / 16) calc(30rem / 16);
    }
}
.form-control-lg + .input-group-append > .btn {
    font-size: calc(28rem / 16);
    line-height: 1;
    padding: calc(10rem / 16) calc(20rem / 16);

    @media screen and (min-width: 768px) {
        line-height: calc(32/35);
        padding: calc(15rem / 16) calc(38rem / 16);
        font-size: calc(35rem / 16);
    }
}
@media screen and (min-width: 768px) {
    .form-control-xl {
        height: calc(60rem / 16);
        font-size: calc(33rem / 16);
    }
}

.form-group {
    margin-bottom: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(35rem / 16);
    }
}
.input-group-append .btn {
    z-index:auto;
}
.form-info {
    font-size: calc(12rem / 16);
    line-height: calc(17/12);
}
/* control label */
.control-label {
    color: var(--color-dark-grey);
    font-family: var(--font-default-bold);
    font-weight: 400;
    font-size: calc(15rem / 16);
    line-height: calc(20/15);
    letter-spacing: calc(2rem / 16);
    margin-bottom: calc(10rem / 16);
}

/* icon submit button */
.form-control-submit-icon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    font-size: calc(20rem / 16);
    line-height: 1;
}
.form-control-submit-icon + .form-control {
    padding-left: calc(30rem / 16);
}
@media screen and (min-width: 768px) {
    .form-control-submit-icon--xl {
        font-size: calc(36rem / 16);
    }
    .form-control-submit-icon--xl + .form-control {
        padding-left: calc(50rem / 16);
    }
}

input[type=number].hide-number-spinner::-webkit-inner-spin-button,
input[type=number].hide-number-spinner::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number].hide-number-spinner::-webkit-outer-spin-button,
input[type=number].hide-number-spinner::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number].hide-number-spinner {
    -moz-appearance:textfield;
}