.btn.sticky-btn {
    @media screen and (min-width: 786px) {
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 12;
        transition: transform 300ms ease-in-out;
        padding: calc(26rem / 16) calc(65rem / 16) calc(20rem / 16);
        font-size: calc(17rem / 16);
    }
}
.sticky-btn__icon {
    vertical-align: -.2em;
    margin-right: calc(8rem / 16);

    @media screen and (min-width: 768px) {
        transition: transform 300ms ease-in-out;
    }
}
@media screen and (min-width: 768px) {
    .sticky-btn.is-affix {
        transform: translateX(calc(100% - calc(70rem / 16)));
    }
    .is-affix .sticky-btn__icon {
        transform: translateX(calc(-40rem / 16));
    }
}