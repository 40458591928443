.text-teaser {
    padding: calc(30rem / 16) calc(15rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(45rem / 16) calc(20rem / 16);
    }
}
.text-teaser__title {
    font-size: calc(22rem / 16);
    line-height: calc(29/27);
    letter-spacing: calc(3rem / 16);
    padding-bottom: calc(14rem / 16);
    margin-bottom: calc(25rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(27rem / 16);
        margin-bottom: calc(34rem / 16);
    }
}
.text-teaser__address{
    font-size: calc(16rem / 16);
    line-height: calc(25/16);
}
.text-teaser__address__title {
    font-size: calc(16rem / 16);
    margin-bottom: calc(10rem / 16);
}