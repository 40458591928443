.arrow-link {
    font-size: calc(15rem / 16);
    letter-spacing: calc(2rem / 16);
}
.arrow-link__icon {
    font-size: calc(9rem / 16);
    margin-top: calc(5rem / 16);
    transition: transform 200ms ease-in-out;

    @media screen and (min-width: 768px) {
        margin-top: 0;
        margin-left: calc(5rem / 16);
        vertical-align: 0.1em;
    }
}
.arrow-link:hover .arrow-link__icon {
    transform: translateX(calc(3rem / 16));
}
