.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease-in-out;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: calc(20rem / 16) calc(15rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(40rem / 16) calc(55rem / 16);
    }
}
.overlay.is-open {
    opacity: 1;
    visibility: visible;
}

.overlay__content {
    padding: calc(30rem / 16) 0;

    @media screen and (min-width: 768px){
        padding: calc(55rem / 16) 0;
    }
}

.overlay__close {
    font-size: calc(15rem / 16);
}
.overlay__close__icon {
    vertical-align: -.2em;
    font-size: calc(19rem / 16);
    margin-left: calc(6rem / 16);
}