.nav-item__dropdown {
    display: none;

    @media screen and (min-width: 768px) {
        position: absolute;
        padding-top: calc(122rem / 16);
        background: rgba(255, 255, 255, 0.95);
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
}
.nav-item.is-open > .nav-item__dropdown {
    display: block;
}
.nav-item__dropdown li + li {
    margin-top: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(57rem / 16);
    }

    @media screen and (min-width: 768px) and (max-height: 990px) {
        margin-top: calc(20rem / 16);
    }

    @media screen and (min-width: 1200px) and (max-height: 990px) {
        margin-top: calc(30rem / 16);
    }
}
.nav-item__dropdown li > a {
    font-size: calc(18rem / 16);
    display: inline-block;
    transition: transform 200ms ease-in-out, color 200ms ease-in-out;

    @media screen and (min-width: 768px) {
        line-height: calc(45/33);
        font-size: calc(33rem / 16);
        text-transform: uppercase;
    }

    @media screen and (min-width: 768px) and (max-height: 990px) {
        font-size: calc(22rem / 16);
    }

    @media screen and (min-width: 1200px) and (max-height: 990px) {
        font-size: calc(28rem / 16);
    }
}
.nav-item__dropdown li > a:hover {
    transform: translateX(calc(-20rem / 16));
    color: var(--color-secondary);
}
.nav-item__dropdown-content {
    font-size: calc(15rem / 16);

    @media screen and (min-width: 768px) {
        padding: 7vh 0;
    }

    @media screen and (min-width: 768px) and (max-height: 990px) {
        padding: 2vh 0;
    }
}
.nav-item__dropdown__close__icon {
    vertical-align: -.2em;
    font-size: calc(19rem / 16);
    margin-left: calc(6rem / 16);
}