.custom-checkbox {
    font-size: calc(15rem/16);
    line-height: calc(20/15);
    padding-left: calc(33rem / 16);
    letter-spacing: calc(1rem / 16);
    position: relative;

    @media screen and (min-width: 786px) {
        letter-spacing: calc(2rem / 16);
    }
}
.custom-checkbox:before,
.custom-checkbox:after {
    content: " ";
    display: table;
}
.custom-checkbox:after {
    clear: both;
}
.custom-checkbox__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-checkbox__box {
    position: absolute;
    left: 0;
    top: calc(-1rem / 16);
    overflow: hidden;
    border: 1px solid var(--color-primary);
    background: #fff;
    width: calc(22rem/16);
    height: calc(22rem/16);
}
.custom-checkbox__box:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: calc(8rem/16);
    color: var(--color-primary);
    visibility: hidden;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box:before {
    visibility: visible;
}
.custom-checkbox__input:focus ~ .custom-checkbox__box {
    border-color: var(--color-primary);
}
.has-error .custom-checkbox__box {
    border-color: var(--color-danger)
}
.custom-checkbox__text {
    display: block;
    overflow: hidden;
}
.custom-checkbox__text a {
    color: var(--color-secondary);
}
.custom-checkbox__text a:hover,
.custom-checkbox__text a:focus,
.custom-checkbox__text a:active {
    color: var(--color-secondary);
    text-decoration: underline;
}