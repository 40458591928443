.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: calc(15rem / 16);
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a {
    color: var(--color-secondary);
}
.wysiwyg a:hover,
.wysiwyg a:focus,
.wysiwyg a:active {
    color: var(--color-secondary);
    text-decoration: underline;
}

.wysiwyg h1, .wysiwyg h2, .wysiwyg h3, .wysiwyg h4, .wysiwyg h5 {
    color: var(--color-primary);
}
.wysiwyg ul {
    list-style: none;
    padding-left: 0;
}
.wysiwyg ul li {
    position: relative;
    padding-left: calc(22rem / 16);
}
.wysiwyg ul li:before {
    content: var(--icon-oval);
    font-family: iconfont;
    speak: none;
    display: inline-block;
    position: absolute;
    top: calc(12rem / 16);
    left: calc(9rem / 16);
    color: var(--color-primary);
    font-size: calc(5rem / 16);
}
.wysiwyg ul li + li {
    margin-top: calc(10rem / 16);
}

.wysiwyg--sm {
    font-size: calc(16rem / 16);
    line-height: calc(33/16);
}

/* wysiwyg headlines */
.wysiwyg h2 {
    @media screen and (min-width: 768px) {
        font-size: calc(44rem / 16);
        letter-spacing: calc(4rem / 16);
        line-height: 1;
        margin-bottom: calc(5rem / 16);
    }
}
.wysiwyg h3 {
    @media screen and (min-width: 768px) {
        font-size: calc(32rem / 16);
        letter-spacing: calc(3.6rem / 16);
        line-height: calc(33/32);
        margin-bottom: calc(5rem / 16);
    }
}