.download-teaser__content {
    padding: calc(20rem / 16) calc(15rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(28rem / 16) calc(15rem / 16);
    }
}
.download-teaser__content__link {
    letter-spacing: calc(2rem / 16);
    font-size: calc(15rem / 16);
}
.download-teaser__content__link__icon {
    font-size: calc(24rem / 16);
    margin-left: calc(5rem / 16);
    vertical-align: -0.2em;
}
.download-teaser__img {
    transition: transform 200ms ease-in-out;
}
.download-teaser:hover .download-teaser__img {
    transform: scale(1.02);
}