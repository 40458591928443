.control-box input[type="radio"],
.control-box input[type="checkbox"] {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.control-box {
    cursor: pointer;
    position: relative;
    width: 100%;
}
.control-box + .control-box {
    margin-top: calc(10rem / 16);
}
.control-box__label {
    background: #fff;
    font-size: calc(15rem / 16);
    padding: calc(15rem / 16);
    border: 1px solid #fff;
    transition: color 200ms ease-in-out, border-color 200ms ease-in-out;

    @media screen and (min-width: 768px) {
        padding: calc(30rem / 16);
    }
}
.control-box input:checked ~ .control-box__label {
    border-color: var(--color-secondary);
    color: var(--color-secondary);
}
.control-box:hover .control-box__label {
    color: var(--color-secondary);
}

/* img label */
.control-box__label--img {
    border: 0;
    padding: calc(15rem / 16) calc(20rem / 16);
    height: calc(90rem / 16);
    display: flex;
    align-items: center;
    justify-content: center;
}
.control-box__label--img img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: .55;
    transition: filter 200ms ease-in-out, opacity 200ms ease-in-out;
}
.control-box input:checked ~ .control-box__label--img img,
.control-box:hover .control-box__label--img img {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
    opacity: 1;
}

/*
.control-box__label {
    overflow: hidden;
    position: relative;
}
.control-box__label:before {
    content: '';
    background-color: red;
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: scale(0.001, 0.001);
}
.control-box input:focus ~ .control-box__label:before {
    animation: effect_dylan 0.8s ease-out;
}
@keyframes effect_dylan {
    50% {
        transform: scale(1.5, 1.5);
        opacity: 0;
    }
    99% {
        transform: scale(0.001, 0.001);
        opacity: 0;
    }
    100% {
        transform: scale(0.001, 0.001);
        opacity: 1;
    }
}*/
