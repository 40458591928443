.img-slide {
    position: relative;
}
.img-slide:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255,255,255,.6);
}
.img-slide__content {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    z-index: 1;
    font-size: calc(15rem / 16);
    letter-spacing: calc(2rem / 16);
}
.img-slide__content__title {
    font-size: calc(30rem / 16);
    line-height: calc(41/37);
    letter-spacing: calc(2rem / 16);
    padding-bottom: calc(12rem / 16);
    margin-bottom: calc(12rem / 16);
    color: var(--color-primary);

    @media screen and (min-width: 768px) {
        font-size: calc(37rem / 16);
        letter-spacing: calc(5rem / 16);
    }
}
.img-slide__content__text {
    font-size: calc(19rem / 16);
    line-height: calc(31/19);
    letter-spacing: normal;
}
.img-slide__img {
    transition: transform 200ms ease-in-out;
}
.img-slide:hover .img-slide__img {
    transform: scale(1.02);
}
.img-slide--dark {
    color: #fff;
}
.img-slide--dark:after {
    background: rgba(0, 54, 108, 0.7);
}
.img-slide--dark .img-slide__content__title {
    color: #fff;
}
.img-slide__content__quote {
    font-size: calc(55rem / 16);
    line-height: 1;
    text-transform: none;

    @media screen and (min-width: 1200px) {
        font-size: calc(80rem / 16);
    }
}