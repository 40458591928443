.breadcrumb {
    color: var(--color-text-default);
    margin: 0;
    padding-top: calc(15rem / 16);
    font-size: calc(12rem / 16);
    letter-spacing: calc(2rem / 16);
}
.breadcrumb-item {
    position: relative;
}
.breadcrumb-item+.breadcrumb-item {
    padding-left: calc(15rem / 16);

    @media screen and (min-width: 768px) {
        padding-left: calc(22rem / 16);
    }
}
.breadcrumb-item+.breadcrumb-item:before {
    content: '';
    width: 1px;
    top: calc(3rem / 16);
    bottom: calc(3rem / 16);
    background: var(--color-text-default);
    position: absolute;
    left: calc(6rem / 16);
    padding: 0;

    @media screen and (min-width: 768px) {
        left: calc(10rem / 16);
    }
}
.breadcrumb-item.active {
    font-family: var(--font-default-bold);
    font-weight: 400;
    color: var(--color-text-default);
}