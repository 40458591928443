.clickable { cursor: pointer;}

/*Text Helper*/
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.text-transform-none {text-transform: none;}

/*Text Color Helper*/
.text-primary-hover:hover { color: var(--color-primary); }
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default); }
.text-dark-grey { color: var(--color-dark-grey);}
.text-secondary, .text-secondary-hover:hover { color: var(--color-secondary);}


.text-red { color: #EB5A4B;}
.text-yellow { color: #FAB95A;}
.text-green { color: #AFD269;}
.text-blue { color: #82CAC3;}

/*Background Color Helper*/
.bg-light-grey {background: var(--color-light-grey)}


/* letter spacings */
.letter-spacing-0 {letter-spacing: normal;}
.letter-spacing-2 {letter-spacing: calc(2rem / 16);}
.letter-spacing-3 {letter-spacing: calc(3rem / 16);}

.img-caption{
    font-size: calc(18rem / 16);
    line-height: 1;
}

.frc-captcha .frc-content {
    min-height: calc(18rem/16);
}