.content-block + .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block {
    margin-top: calc(30rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(65rem/16);
    }
}

.content-block--img-text__text {
    letter-spacing: calc(2rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(19rem / 16);
        letter-spacing: calc(3rem / 16);
    }
}