body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: calc(16rem / 16);
    line-height: calc(26/16);

    @media screen and (min-width: 768px) {
        font-size: calc(17rem / 16);
        line-height: calc(33/19);
    }

    @media screen and (min-width: 1200px) {
        font-size: var(--font-size-default);
    }
}

b,
strong,
.strong,
.font-default-bold {
    font-family: var(--font-default-bold);
    font-weight: normal;
}

.font-vista-slab {
    font-family: var(--font-vista-slab);
    font-weight: 400;
}
.font-special {
    font-family: var(--font-special);
    font-weight: 400;
}
.font-default {
    font-family: var(--font-default);
    font-weight: 400;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: var(--font-vista-slab);
    font-weight: 400;
    margin-top: 0;
    display: block;
    color: inherit;
}

h1, .h1 {
    font-size: calc(52rem / 16);
    line-height: 0.86;
    letter-spacing: calc(2.5rem / 16);
    font-family: var(--font-special);

    @media screen and (min-width: 768px) {
        font-size: calc(120rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(161rem / 16);
    }
}
h2, .h2 {
    font-size: calc(28rem/16);
    line-height: calc(91/77);

    @media screen and (min-width: 768px) {
        font-size: calc(60rem/16);
        letter-spacing: calc(7rem / 16);
        text-transform: uppercase;
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(77rem/16);
    }
}
h3, .h3 {
    font-size: calc(28rem/16);

    @media screen and (min-width: 768px) {
        text-transform: uppercase;
        font-size: calc(55rem/16);
        letter-spacing: calc(5rem / 16);
    }
}
h4, .h4 {
    font-size: calc(24rem/16);
    text-transform: uppercase;

    @media screen and (min-width: 768px) {
        font-size: calc(37rem/16);
        letter-spacing: calc(5rem / 16);
    }
}
h5, .h5 {
    font-size: calc(22rem / 16);
    text-transform: uppercase;
    margin-bottom: calc(5rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(32rem / 16);
        letter-spacing: calc(3.6rem / 16);
        line-height: calc(33/32);
    }
}
h6, .h6 {
    font-size: calc(22rem / 16);
    letter-spacing: calc(1.83rem / 16);
    line-height: calc(22/22);
    text-transform: uppercase;
}

.intro {
    font-size: calc(20rem / 16);
    line-height: calc(32/20);

    @media screen and (min-width: 768px) {
        font-size: calc(28rem / 16);
        line-height: calc(66/33);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(33rem / 16);
    }
}