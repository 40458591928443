.loading-overlay-container {
    position: relative;
    overflow: hidden;
}
.loading-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, .6);
    z-index: 102;
    text-align: center;

    @media screen and (min-width: 768px) {
        position: absolute;
    }
}