.custom-file, .custom-file-input {
    height: calc(70rem / 16);
    cursor: pointer;

    @media screen and (min-width: 768px) {
        height: calc(100rem / 16);
    }
}
.custom-file + .custom-file {
    margin-top: calc(10rem / 16);
}
.custom-file-label {
    height: calc(70rem / 16);
    background: var(--color-light-grey);
    border-color: var(--color-light-grey);
    padding: calc(10rem / 16);
    cursor: pointer;

    @media screen and (min-width: 768px) {
        height: calc(100rem / 16);
        padding: calc(10rem / 16) calc(20rem / 16);
    }
}
.custom-file .custom-file-input ~ .custom-file-label:after {
    content: '';
    background: transparent;
    border: none;
}

.custom-file-label__text {
    overflow: hidden;
    text-overflow: ellipsis;
}