.share-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.share-list li {
    display: inline-block;
}
.share-list li + li {
    margin-left: calc(16rem / 16);
}
.share-list li a {
    height: calc(48rem / 16);
    width: calc(48rem / 16);
    border-radius: 50%;
    display: inline-block;
    position: relative;
    color: #fff;
    font-size: calc(20rem / 16);
    background: var(--color-dark-grey);
    transition: background 200ms ease-in-out;
}

.share-list .facebook > a:hover {
    background: #3b5998;
}
.share-list .twitter > a:hover {
    background: #55acee;
}
.share-list .linkedin > a:hover {
    background: #0077B5;
}
.share-list .mail > a:hover {
    background: var(--color-dark-grey);
}

.share-list .shariff-button .fab:before {
    content: '';
    line-height: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-family: iconfont;
}

.shariff-button.facebook .fab:before {
    content:var(--icon-facebook);
}

.shariff-button.twitter .fab:before {
    content:var(--icon-twitter);
}

.shariff-button.pinterest .fab:before {
    content:var(--icon-pinterest);
}

.shariff-button.mail .fas:before {
    content:var(--icon-mail);
}

.shariff-button.whatsapp .fab:before {
    content:var(--icon-whatsapp);
}

.shariff-button.addthis .fas:before {
    content:var(--icon-addthis);
}

.shariff-button.linkedin .fab:before {
    content:var(--icon-linkedin);
}