.contact {
    font-size: calc(16rem / 16);
    line-height: calc(22/16);
}
.contact__img {
    margin-bottom: calc(30rem / 16);
}
.contact__title {
    font-size: calc(24rem / 16);
    /*line-height: 25px;*/
    text-transform: none;
}