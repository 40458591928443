@media screen and (max-width: 767px) {
    .navbar-collapse {
        position: absolute;
        height: calc(100vh - calc(60rem / 16));
        background: #fff;
        color: var(--color-dark);
        left: 0;
        right: 0;
        top: calc(60rem / 16);
        transform: translateX(-100%);
        transition: transform 250ms ease-in-out;
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling:touch;
    }
    .is-open .navbar-collapse {
        transform: translateX(0);
    }
    .navbar-main {
        height: calc(60rem / 16);
    }
    .is-open.navbar-main {
        background: #fff;
    }
    .navbar-nav {
        display: block;
        text-align: center;
        padding: calc(50rem / 16) 0;
        min-height: calc(100% - calc(60rem / 16));
    }
    .navbar-nav .nav-link {
        display: inline-block;
    }
    .navbar-brand__icon {
        font-size: calc(35rem / 16);
    }

    /* toggle button */
    .navbar-toggler {
        display: flex;
        align-items: center;
        cursor: pointer;
        border: 0;
        color: var(--color-default);
    }
    .navbar-toggler__bar {
        display: block;
        width: calc(33rem / 16);
        height: calc(2rem / 16);
        background: var(--color-default);
        transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
        transform-origin: left center;
    }
    .navbar-toggler__bar:nth-child(1),
    .navbar-toggler__bar:nth-child(3) {
        width: calc(25rem / 16);
        margin-left: auto;
    }
    .navbar-toggler__bar + .navbar-toggler__bar {
        margin-top: calc(5rem / 16);
    }
    .is-open .navbar-toggler__bar {
        transform: rotate(45deg) translateX(-2px) translateY(-2px);
    }
    .is-open .navbar-toggler__bar:nth-child(3) {
        transform: rotate(-45deg) translateX(0) translateY(0);
    }
    .is-open .navbar-toggler__bar:nth-child(2) {
        opacity: 0;
    }
    .navbar-toggler:focus,
    .navbar-toggler:hover {
        outline: none;
    }
    .navbar:not(.is-open) .navbar-toggler:hover .navbar-toggler__bar:last-child {
        transform: scaleX(1);
    }
}