.brand-teaser {
    padding: calc(22rem / 16);
    position: relative;
    overflow: hidden;
}
.brand-teaser__logo {
    margin-bottom: calc(10rem / 16);
    width: calc(100rem / 16);

    @media screen and (min-width: 768px) {
        width: calc(200rem / 16);
        max-height: calc(108rem / 16);
    }
}
.brand-teaser__logo > img {
    max-height: 100%;
}
.brand-teaser__logo > h2 {
    font-size: 0;
}
/*.brand-teaser__img {
    transition: transform 200ms ease-in-out;
}
.brand-teaser:hover .brand-teaser__img {
    transform: scale(1.04);
}*/
.brand-teaser__content {
    padding-top: calc(15rem / 16);
    font-size: calc(14rem / 16);

    @media screen and (min-width: 768px) {
        overflow-y: auto;
        overflow-x: hidden;
        opacity: 0;
        transition: opacity 300ms ease-in-out;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: var(--color-primary);
        padding: calc(22rem / 16);
        color: #fff;
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(15rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(17rem / 16);
        line-height: calc(33/17);
    }
}
.brand-teaser__content__title {
    font-size: calc(20rem / 16);
    letter-spacing: calc(4rem / 16);
    margin-bottom: calc(10rem / 16);

    @media screen and (min-width: 1400px) {
        margin-bottom: calc(25rem / 16);
    }
}
.brand-teaser:hover .brand-teaser__content {
    opacity: 1;
}