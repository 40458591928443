.sidebar-box {
    padding: calc(12rem / 16);
}
.sidebar-box__title {
    font-size: calc(22rem / 16);
    line-height: calc(29/27);
    letter-spacing: calc(3rem / 16);
    padding-bottom: calc(14rem / 16);
    margin: calc(10rem / 16) 0 calc(25rem / 16) 0;

    @media screen and (min-width: 768px) {
        font-size: calc(27rem / 16);
        margin: calc(20rem / 16) 0 calc(34rem / 16) 0;
    }
}
.sidebar-box + .sidebar-box {
    margin-top: calc(10rem / 16);
}