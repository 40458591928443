.main-footer {
    background-image: url('/static/debug/img/branding-visual.svg');
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: contain;
    padding: calc(40rem / 16) 0;
    font-size: calc(14rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(15rem / 16);
        letter-spacing: calc(2rem / 16);
        background-position: center calc(15rem / 16);
        padding: calc(80rem / 16) 0;
    }
}
.main-footer__logo {
    width: 100%;
    max-width: calc(150rem / 16);
    margin-bottom: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        max-width: calc(180rem / 16);
        margin: calc(-25rem / 16) 0 calc(90rem / 16);
    }
}
.main-footer__bottom {
    margin-top: calc(40rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(90rem / 16);
    }
}
.social-link {
    width: calc(48rem / 16);
    height: calc(48rem / 16);
    border-radius: 50%;
    background: var(--color-dark-grey);
    color: #fff;
    position: relative;
    transition: background 200ms ease-in-out;
    font-size: calc(20rem / 16);
}
.social-link:hover {
    background: var(--color-primary);
    color: #fff;
}
.social-link__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%)translateY(-50%);
}


.elements:hover {
    color: #f30;
}
.elements__icon {
    margin-left: calc(4rem / 16);
    vertical-align: -0.1em;
    transform-origin: center;
    transform: scale(1) rotate(0);
    transition: transform 500ms ease-in-out;
}
.elements:hover .elements__icon {
    transform: scale(1.4) rotate(-360deg);
}