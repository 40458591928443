.nav-tabs {
    border: 0;
    margin-bottom: calc(40rem / 16);
}
.nav-tabs .nav-link {
    border: 0;
    letter-spacing: calc(5rem / 16);
    color: var(--color-dark-grey);
    font-size: calc(17rem / 16);
    margin: 0 calc(23rem / 16);
    padding: calc(5rem / 16);
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link.active {
    color: var(--color-primary);
    background: none;
}