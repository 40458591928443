.scroll-spy,
.scroll-spy.is-affix { /* affix quick fix before go live */
    position: fixed!important;
    bottom: calc(32rem / 16)!important;
    left: calc(32rem / 16)!important;
    top: auto!important;
    z-index: 10;
}

.scroll-spy{
    bottom: calc(135rem / 16)!important;
    width: calc(20rem/16)!important;
}

.scroll-spy.is-affix{
    bottom: calc(32rem / 16)!important;
}

.scroll-spy__item {
    background: transparent;
    padding: 0;
    border: 0;
    font-size: calc(12rem / 16);
    opacity: .4;
    transition: opacity 200ms ease-in-out;
}
.scroll-spy__item + .scroll-spy__item {
    margin-top: calc(10rem / 16);
}
.scroll-spy__item:hover,
.scroll-spy__item.active {
    background: transparent;
    opacity: 1;
}