.icon-teaser {
    padding: calc(25rem / 16) calc(5rem / 16);
    font-size: calc(20rem / 16);
    letter-spacing: calc(2rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(50rem / 16) calc(20rem / 16);
        font-size: calc(33rem / 16);
        letter-spacing: calc(5rem / 16);
    }
}
.icon-teaser__icon {
    font-size: calc(60rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(124rem / 16);
    }
}