.teaser__img:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--color-primary);
    opacity: .2;
    transition: opacity 200ms ease-in-out;
}
.teaser:hover .teaser__img:after {
    opacity: .5;
}
.teaser__head {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: calc(22rem / 16);
}
.teaser__head__title {
    font-size: calc(22rem / 16);
    line-height: 1;
    letter-spacing: calc(3rem / 16);
}
.teaser__content {
    padding: calc(10rem / 16) 0;
}
.teaser__content__subtitle {
    font-size: calc(15rem / 16);
    line-height: 1;
    letter-spacing: calc(5rem / 16);
}