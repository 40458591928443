.product-teaser {
    padding: calc(20rem / 16) calc(22rem / 16);
    position: relative;
    font-size: calc(15rem / 16);
    letter-spacing: calc(2rem / 16);
}
.product-teaser__title{
    font-size: calc(20rem / 16);
    line-height: calc(22/15);
    letter-spacing: calc(4rem / 16);
    margin-bottom: calc(5rem / 16);
}
.product-teaser__img {
    transition: transform 200ms ease-in-out;
}
.product-teaser:hover .product-teaser__img {
    transform: scale(1.04);
}