.grid-slider-container .slick-slide {
    padding: 0 calc(5rem / 16);
}
.grid-slider {
    max-width: 1696px;
    margin-left: auto;
    width: 100%;
    position: relative;
}
.grid-slider-container {
    overflow: hidden;
    padding-left: calc(30rem / 16);
}
.grid-slider-container .slick-list {
    width: 100%;
    overflow: visible;
}