.navbar.navbar-main {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    padding: 0 calc(10rem / 16);
    transition: background 200ms ease-in-out;

    @media screen and (min-width: 768px) {
        padding: 0 calc(15rem / 16);
    }

    @media screen and (min-width: 1200px) {
        padding: 0 calc(50rem / 16);
    }
}
.navbar-main.is-affix {
    position: fixed;
    background: rgba(255,255,255,.8);
}
.navbar-brand {
    position: relative;
    height: calc(30rem / 16);
    margin: 0;

    @media screen and (min-width: 768px) {
        height: calc(120rem / 16);
        min-width: calc(152rem / 16);
    }
}
.is-affix .navbar-brand {
    @media screen and (min-width: 768px) {
        height: calc(80rem / 16);
    }
}
.navbar-brand__img {
    max-width: 100%;
    max-height: 100%;

    @media screen and (min-width: 768px) {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: opacity 200ms ease-in-out;
    }
}
.navbar-nav .nav-item:not(:first-child) {
    margin-top: calc(30rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: 0;
        margin-left: calc(25rem / 16);
    }

    @media screen and (min-width: 1200px) {
        margin-top: 0;
        margin-left: calc(35rem / 16);
    }

    @media screen and (min-width: 1400px) {
        margin-left: calc(45rem / 16);
    }
}
.navbar-nav .nav-link {
    text-transform: uppercase;
    font-size: calc(24rem / 16);
    line-height: calc(23/17);
    position: relative;

    @media screen and (min-width: 768px) {
        font-size: calc(17rem / 16);
    }
}
.navbar-nav .nav-link:after {
    content: '';
    position: absolute;
    bottom: calc(-8rem / 16);
    left: 1px;
    right: 1px;
    height: calc(3rem / 16);
    border-radius: calc(1.5rem / 16);
    background: var(--color-primary);
    opacity: 0;
    transform: translateY(-100%);
    transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
}
.is-open > .nav-link:after {
    transform: translateY(0);
    opacity: 1;
}
@media screen and (min-width: 768px) {
    .nav-link:hover:after {
        transform: translateY(0);
        opacity: 1;
    }
}
.nav-link__icon {
    margin-right: calc(6rem / 16);
    font-size: calc(18rem / 16);

    @media screen and (min-width: 768px) {
        vertical-align: -.15em;
        font-size: calc(16rem / 16);
    }
}
.active > .nav-link {
    font-family: var(--font-default-bold);
    font-weight: 400;
}
@media screen and (min-width: 768px){
    .is-affix .hidden-affix,
    .visible-affix {
        opacity: 0;
    }
    .is-affix .visible-affix {
        opacity: 1;
    }
}