.flag {
    display: inline-block;
    background-repeat: no-repeat;
    background-image: url(/static/debug/img/flags/sprite.svg);
}
.flag-aus {
    width: 77px;
    height: 77px;
    background-position: 0 0;
}
.flag-aut {
    width: 77px;
    height: 77px;
    background-position: -93px 0;
}
.flag-bgr {
    width: 77px;
    height: 77px;
    background-position: -186px 0;
}
.flag-blr {
    width: 77px;
    height: 77px;
    background-position: -279px 0;
}
.flag-che {
    width: 77px;
    height: 77px;
    background-position: -372px 0;
}
.flag-cyp {
    width: 77px;
    height: 77px;
    background-position: -465px 0;
}
.flag-dnk {
    width: 77px;
    height: 77px;
    background-position: -558px 0;
}
.flag-est {
    width: 77px;
    height: 77px;
    background-position: -651px 0;
}
.flag-fin {
    width: 77px;
    height: 77px;
    background-position: -744px 0;
}
.flag-ger {
    width: 77px;
    height: 77px;
    background-position: -837px 0;
}
.flag-hrv {
    width: 77px;
    height: 77px;
    background-position: -930px 0;
}
.flag-hun {
    width: 77px;
    height: 77px;
    background-position: -1023px 0;
}
.flag-isr {
    width: 77px;
    height: 77px;
    background-position: -1116px 0;
}
.flag-ita {
    width: 77px;
    height: 77px;
    background-position: -1209px 0;
}
.flag-jor {
    width: 77px;
    height: 77px;
    background-position: -1302px 0;
}
.flag-kwt {
    width: 77px;
    height: 77px;
    background-position: -1395px 0;
}
.flag-lbn {
    width: 77px;
    height: 77px;
    background-position: 0 -93px;
}
.flag-ltu {
    width: 77px;
    height: 77px;
    background-position: -93px -93px;
}
.flag-lva {
    width: 77px;
    height: 77px;
    background-position: -186px -93px;
}
.flag-mex {
    width: 77px;
    height: 77px;
    background-position: -279px -93px;
}
.flag-nor {
    width: 77px;
    height: 77px;
    background-position: -372px -93px;
}
.flag-rou {
    width: 77px;
    height: 77px;
    background-position: -465px -93px;
}
.flag-rus {
    width: 77px;
    height: 77px;
    background-position: -558px -93px;
}
.flag-svn {
    width: 77px;
    height: 77px;
    background-position: -651px -93px;
}
.flag-swe {
    width: 77px;
    height: 77px;
    background-position: -744px -93px;
}
.flag-tld {
    width: 77px;
    height: 77px;
    background-position: -837px -93px;
}
.flag-tur {
    width: 77px;
    height: 77px;
    background-position: -930px -93px;
}
.flag-uae {
    width: 77px;
    height: 77px;
    background-position: -1023px -93px;
}
.flag-aut1 {
    width: 77px;
    height: 77px;
    background-position: -1116px -93px;
}
.flag-aus1 {
    width: 77px;
    height: 77px;
    background-position: -1209px -93px;
}
.flag-chevron {
    width: 141px;
    height: 77px;
    background-position: -1302px -93px;
}
.flag-uk {
    width: 77px;
    height: 77px;
    background-position: 0 -186px;
}
.flag-serbia {
    width: 77px;
    height: 77px;
    background-position: -93px -186px;
}
.flag-nz {
    width: 77px;
    height: 77px;
    background-position: -186px -186px;
}