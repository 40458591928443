.slide-sm,
.slide.slide-sm {
    margin-top: calc(30rem/16);
    margin-bottom: calc(30rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(50rem / 16);
        margin-bottom: calc(50rem / 16);
    }
}
.slide {
    margin-top: calc(50rem/16);
    margin-bottom: calc(50rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(90rem / 16);
        margin-bottom: calc(90rem / 16);
    }
}

/* slides with background color / image */
.slide-sm[class*='bg-'] {
    margin: 0;
    padding-top: calc(30rem / 16);
    padding-bottom: calc(30rem / 16);

    @media screen and (min-width: 768px) {
        padding-top: calc(50rem / 16);
        padding-bottom: calc(50rem / 16);
    }
}
.slide[class*='bg-'] {
    margin: 0;
    padding-top: calc(50rem / 16);
    padding-bottom: calc(50rem / 16);

    @media screen and (min-width: 768px) {
        padding-top: calc(90rem / 16);
        padding-bottom: calc(90rem / 16);
    }
}

.slide.slide--full-height,
.slide--full-height {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 992px) {
        position: relative;
        margin: 0;
        padding-top: calc(120rem / 16);
        padding-bottom: calc(120rem / 16);
    }
}
.slide--full-height .slide__content-wrapper{
    width: 100%;

    @media screen and (min-width: 992px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: calc(100vh - calc(240rem / 16));
    }
}
.slide--full-height .slide__content {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: calc(30rem / 16) 0;

    @media screen and (min-width: 768px) {
        padding: calc(40rem / 16) 0;
    }
}

.slide.slide--img-bg,
.slide--img-bg {
    background-color: var(--color-light-grey);
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: 0;
    padding-top: calc(50rem/16);
    padding-bottom: calc(50rem/16);
}
.slide--img-bg:before {
    content: '';
    position: absolute;
    pointer-events: none;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    background: rgba(255,255,255,.5);
}

/* slide head */
.slide__head {
    position: relative;
    margin-bottom: calc(30rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(55rem / 16);
    }
}
.slide--full-height .slide__head {
    margin-bottom: 0;
}
.slide__head__pre-title {
    font-size: calc(17rem / 16);
    letter-spacing: calc(2rem / 16);

    @media screen and (min-width: 768px) {
        letter-spacing: calc(5rem / 16);
    }
}