.slider-container {
    position: relative;
}
.slider-container .slick-list {
    padding-bottom: calc(80rem / 16);
}
.slider-item {
    overflow: hidden;
}
.slider-info-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
}
.slider-info {
    font-size: calc(12rem / 16);
    letter-spacing: calc(2rem / 16);
    position: relative;
    padding-right: calc(10rem / 16);
}
.slider-info:after {
    content: '';
    height: 1px;
    background: currentColor;
    position: absolute;
    left: 100%;
    right: -9999em;
    top: 50%;
}
.slider-info__icon {
    width: calc(39rem / 16);
    height: calc(39rem / 16);
    border: 1px solid currentColor;
    border-radius: 50%;
    font-size: calc(18rem / 16);
}
.slider-info__icon:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}

.slick-slide > div {
    width: 100%;
}

/* slider arrows */
.slick-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0;
    transform: translateX(-100%) translateY(-50%);
    font-size: calc(24rem / 16);
    padding: 0 calc(10rem / 16);
    transition: color 200ms ease-in-out, opacity 200ms ease-in-out;
    color: var(--color-secondary);
    line-height: 1;
}
.slick-arrow.slick-next {
    left: auto;
    right: 0;
    transform: translateX(100%) translateY(-50%);
}
.slick-arrow:focus {
    outline: none;
}
.slick-arrow:focus,
.slick-arrow:hover {
    color: var(--color-primary);
}
.slick-arrow.slick-disabled {
    opacity: 0;
    pointer-events: none;
}

/* slick dots */
.slick-dots {
    list-style: none;
    margin: calc(20rem / 16) 0 0;
    padding: 0;
    font-size: 0;
    text-align: center;
}
.slick-dots li {
    display: inline-block;
}
.slick-dots li:not(:last-child) {
    margin-right: calc(14rem / 16);
}
.slick-dots button {
    position: relative;
    border: 0;
    width: calc(14rem / 16);
    cursor: pointer;
    background: var(--color-dark-grey);
    height: calc(4rem / 16);
    border-radius: calc(2rem / 16);
}
.slick-active button {
    background: var(--color-primary);
}

/* slider gutters */
[class*=" slider--gutter-"] .slick-track {
    display: flex;
}
[class*=" slider--gutter-"] .slick-slide {
    height: auto;
    display: flex;
}
[class*=" slider--gutter-"] .slick-slide .slider-item {
    height: 100%;
}
.slider--gutter-width-10 .slick-list {
    margin: 0 calc(-5rem / 16);
}
.slider--gutter-width-10 .slider-item {
    padding: 0 calc(5rem / 16);
}