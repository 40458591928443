/* basic pagination styles in ..bootstrap/scss/_variables.custom.scss */
.pagination {
    font-size: calc(18rem / 16);
}
.page-item + .page-item {
    margin-left: calc(8rem / 16);
}
.page-link__icon {
    font-size: calc(8rem / 16);
    vertical-align: 0.2em;
}