.page-heading {
    position: relative;
    padding-top: calc(80rem / 16);
    padding-bottom: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        padding-top: calc(200rem / 16);
        padding-bottom: calc(40rem / 16);
    }
}
.page-heading__subtitle {
    @media screen and (min-width: 768px) {
        font-size: calc(62rem / 16);
        letter-spacing: calc(5rem / 16);
    }
    @media screen and (min-width: 1200px) {
        font-size: calc(71rem / 16);
    }
}
.page-heading__intro {
    margin-top: calc(30rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(85rem / 16);
    }
}

.page-heading--sm .page-heading__subtitle {
    @media screen and (min-width: 768px) {
        font-size: calc(42rem / 16);
    }
}
.page-heading--sm .page-heading__intro {
    margin-top: calc(15rem / 16);
}

.hero + .page-heading {
    padding-top: calc(70rem / 16);
}