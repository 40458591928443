.decorated-hl {
    position: relative;
    padding-bottom: calc(20rem / 16);
    margin-bottom: calc(20rem / 16);
}
.decorated-hl:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: calc(3rem / 16);
    width: calc(94rem / 16);
    border-radius: calc(1.5rem / 16);
    background: currentColor;
}